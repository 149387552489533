/**
 * Everything you need to show the login!
 *
 * Main methods are startLogin, which will show the login form in the default
 * form, and loginInPage which will show the login form in the standard dom
 * element for piano experiences in articles.
 *
 */

import 'url-search-params-polyfill';
import { startPianoLogin, pianoLogout, showPianoPendingWidget } from './pianoCallbacks';
import { DEFAULT_SERVICE, getEdition, getDomainName } from './profiling';
import { pipe, getThemeColor } from '../utility';

// global objects
const pianoWidgetSelector = '#piano-widget';
const mainLoginButtonSelector = '#login-button';
// const mainLoginButtonSelector = '.network-menu .menu-item--login .login-button';
const mobileLoginButtonSelector = '.mobile-menu .mobile-menu__login';
const networkLoginButtonSelector = '.network-menu .menu-item--login';

const $ = window.$;
const MYQN_ORIGIN = window.MYQN_ORIGIN;
const AUTH0_DOMAIN = window.AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = window.AUTH0_CLIENT_ID;
const AUTH0_CONNECTION = window.AUTH0_CONNECTION;

// utility functions
const getNameOrUsername = (userInfo) =>
  userInfo.firstName || userInfo.given_name || userInfo.lastName || userInfo.email;
const getEllipsedString = (length) => (str) =>
  str.length > length ? `${str.substring(0, length)}...` : str;
// https://eu.ui-avatars.com/
// "No data is being stored on our serveres. Monitoring & logging is entirely disabled."
const getUIAvatar = (name) => {
  // In some case, email can be a fallback for name. We avoid sending email over the
  // network removing the domain component of the email, if present.
  // Further, we replace punctuation with spaces, to handle the common name.surname pattern.
  let sanitizedName = name.replace(/[._-]/g, ' ');
  if (sanitizedName.indexOf('@') > 0) {
    sanitizedName = sanitizedName.substring(0, sanitizedName.indexOf('@'));
  }
  const themeColor = getThemeColor().substring(1); // remove leading #
  return `https://eu.ui-avatars.com/api/?name=${encodeURIComponent(
    sanitizedName,
  )}&color=ffffff&background=${themeColor}`;
};

const getDefaultAvatar = (userInfo) => getUIAvatar(getNameOrUsername(userInfo));

function loginEvent(e) {
  console.log('target click login', 'A2', e);
  e.preventDefault();
  startLogin();
}
function initAnonymousUserStatusWidget() {
  console.log($('login-btn', mainLoginButtonSelector));
  const targetNotLogged =
    $(mainLoginButtonSelector).length > 0
      ? $(mainLoginButtonSelector)
      : $(mobileLoginButtonSelector);
  console.log('init widget', 'A1', targetNotLogged);
  document.addEventListener('login-event', loginEvent);
  // targetNotLogged.on('click', (e) => {
  //   console.log('target click login', 'A2');
  //   e.preventDefault();
  //   startLogin();
  // });
}

function initLoggedUserStatusWidget(userInfo) {
  const username = pipe(getNameOrUsername, getEllipsedString(15))(userInfo);
  const target =
    $(networkLoginButtonSelector).length > 0
      ? $(networkLoginButtonSelector)
      : $(mobileLoginButtonSelector);

  // user profile
  const userProfile = document.createElement('div');
  userProfile.setAttribute('class', 'user-profile');
  // user info
  const userInfoContainer = document.createElement('div');
  userInfoContainer.setAttribute('class', 'user-info');
  // append user info
  userProfile.appendChild(userInfoContainer);
  // user name
  const userInfoName = document.createElement('div');
  userInfoName.setAttribute('class', 'user-info__name');
  userInfoName.innerText = username;
  // append user name
  userInfoContainer.appendChild(userInfoName);

  const avatar = userInfo.avatar || getDefaultAvatar(userInfo);
  // user picture
  const userPicture = document.createElement('div');
  userPicture.setAttribute('class', 'user-picture');
  // append user picture
  userProfile.appendChild(userPicture);

  // user image
  const userImage = document.createElement('img');
  userImage.setAttribute('class', 'user-picture__image');
  userImage.setAttribute('src', avatar);
  // append user image
  userPicture.appendChild(userImage);

  // user panel
  const userPanel = document.createElement('div');
  userPanel.setAttribute('class', 'user-panel');
  // append user panel
  userProfile.appendChild(userPanel);

  // user panel panel link
  const userPanelProfileLink = document.createElement('a');
  userPanelProfileLink.setAttribute('class', 'user-panel__link');
  userPanelProfileLink.setAttribute('href', '/account');
  userPanelProfileLink.innerText = 'Account';
  // append user panel link
  userPanel.appendChild(userPanelProfileLink);

  // user panel logout link
  const userPanelLogoutLink = document.createElement('a');
  userPanelLogoutLink.setAttribute('class', 'user-panel__link');
  userPanelLogoutLink.setAttribute('id', 'logout-anchor');
  userPanelLogoutLink.innerText = 'logout';
  userPanelLogoutLink.href = '#';
  userPanelLogoutLink.addEventListener('click', function (e) {
    e.preventDefault();
    logout();
  });
  // append user panel link
  userPanel.appendChild(userPanelLogoutLink);

  // append template
  target.html(userProfile);
}

/**
 * Handle header status.
 *
 * Creates links and icons for logged in users and anonymous users,
 * both on mobile and desktop.
 */
function initUserStatusWidget(userInfo) {
  console.log('init user status widget', 'A5', userInfo);
  if (userInfo.loginStatus === 'Anonymous') {
    console.log('init user status - anonymous', 'A6', userInfo.loginStatus);
    initAnonymousUserStatusWidget();
  } else {
    console.log('init user status - not anonymous', 'A7', userInfo);
    initLoggedUserStatusWidget(userInfo);
  }
}

function openAndReloadOnClose(url, callback, failureCallback) {
  const win = window.open(url);
  if (win !== null) {
    const timer = setInterval(function () {
      if (win.closed) {
        clearInterval(timer);
        if (typeof callback === 'function') callback();
      }
    }, 100);
  } else {
    if (typeof failureCallback === 'function') failureCallback();
  }
}

/**
 * Creates a login url
 *
 * @param {string} extraParams Parameters added to redirect_uri parameter of Auth0 login.
 * @param {boolean} embedMode The login will be shown stand-alone or in iframe?
 * @return {string} Valid URL usable for login.
 */
function createLoginUrl(extraParams, embedModeFlag) {
  const embedMode = false || embedModeFlag;
  const parsedParams = new URLSearchParams(extraParams); // TODO IE compatibility issue!
  const serviceName = parsedParams.get('primary_service') || DEFAULT_SERVICE;
  const domainName = getDomainName();
  extraParams = extraParams || '';
  if (extraParams) extraParams += '&';
  extraParams += 'primary_service=';
  extraParams += serviceName;
  extraParams += '&primary_domain=';
  extraParams += domainName;
  const primaryEdition = getEdition();
  if (primaryEdition) {
    extraParams += '&primary_edition=';
    extraParams += primaryEdition;
  }
  const localExtraParams = new URLSearchParams(window.location.search);
  localExtraParams.delete('nocache');
  extraParams = localExtraParams.toString()
    ? extraParams + '&' + localExtraParams.toString()
    : extraParams;
  let redirect_url = 'https://';
  redirect_url += document.location.host;
  redirect_url += '/a0callback?';
  redirect_url += extraParams !== '' ? extraParams : '';

  let url = 'https://';
  url += AUTH0_DOMAIN;
  url += '/authorize?';
  // const url = "http://localhost:3004/?";
  url += 'client_id=';
  url += AUTH0_CLIENT_ID;
  url += '&response_type=code&connection=';
  url += AUTH0_CONNECTION;
  url += '&scope=openid%20email%20profile&service=';
  url += serviceName;
  url += '&redirect_uri=';
  url += encodeURIComponent(redirect_url);
  if (embedMode) url += '&embed';
  return url;
}

function createCommunityUrl(extraParams) {
  const parsedParams = new URLSearchParams(extraParams);
  if (!parsedParams.continue_url) {
    parsedParams.set('continue_url', window.location.href);
  }
  const primaryEdition = getEdition();
  if (primaryEdition) {
    parsedParams.set('primary_edition', primaryEdition);
  }
  return '/community/iscrizione?' + new URLSearchParams(parsedParams);
}

function resendConfirmEmail(user) {
  const user_id = user.sub.match(/[^/]+$/);
  $.post(`${MYQN_ORIGIN}/resend-verification-email?user_id=${user_id}`);
}

function createPendingWidgetInElement(elementSelector, loginButtonHandler) {
  function disableButton() {
    $('.pending').addClass('email--sent');
  }
  window.authSession.then((user) => {
    const widget = $('<div class="pending"><div class="pending__image"></div></div');
    const head = $(
      '<h2 class="pending__title">Il tuo indirizzo email non è stato ancora confermato. Controlla la tua email e segui le istruzioni.</h2>',
    );

    const resend = $('<div class="pending__email"></div>');
    const resendEmail = $(`<span>${user.email}</span>`);
    const resendEmailButton = $('<a href="#">Invia di nuovo</a>').on('click', (ev) => {
      ev.preventDefault();
      resendConfirmEmail(user);
      disableButton();
    });
    resend.append(resendEmail);
    resend.append(resendEmailButton);

    const loginButton = $('<a href="#newsletter--mailupform">qui</a>').on(
      'click',
      loginButtonHandler,
    );
    const loginParagraph = $('<p class="pending__description"></p>');

    loginParagraph.append(
      'Ti abbiamo inviato una email di conferma a questo indirizzo, una volta confermata la tua email clicca ',
      loginButton,
      ' per continuare.',
    );
    const footer = $(
      '<div class="pending__footer">Se non hai ricevuto email, controlla nello spam.</div>',
    );
    widget.append(head, resend, loginParagraph, footer);

    $(elementSelector).after(widget);
  });
}

function createPendingWidget(loginButtonHandler) {
  showPianoPendingWidget();
}

function loginInPage() {
  startPianoLogin(pianoWidgetSelector);
}

function receiveLoginComplete(event) {
  if (!event.origin.match(/quotidiano\.net$/) && !event.origin.match(/auth0\.com$/)) {
    return;
  }
  if (!event.data.sender || event.data.sender !== 'universalLogin') {
    return;
  }
  console.debug('message from universal login');
  if (!event.data.event || event.data.event !== 'loginComplete') {
    return;
  }
  console.debug('login complete');
  if (typeof window.loginComplete === 'function') window.loginComplete();
}

/*
 * Piano composer messages handlers
 */
function receiveStartLogin(event) {
  if (!event.origin.match(/tinypass\.com$/)) {
    return;
  }
  console.debug('message from tinypass', event.data);
  if (!event.data.sender || event.data.sender !== 'pianoExperience') {
    return;
  }
  console.debug('message from pianoExperience');
  if (!event.data.event || event.data.event !== 'startLogin') {
    return;
  }
  console.debug('requested login');
  const params = event.data.params || '';
  startLogin(createCommunityUrl(new URLSearchParams(params).toString()), true);
}

/**
 * Show login form, or perform silent login
 * @param {string} url ignored. url of the login page
 * @param {boolean} openInTab ignored. tell if login should be in another tab. default is to open in same window
 */
function startLogin(url, openInTab) {
  console.log('start login', 'A3');
  startPianoLogin();
}

function logout() {
  console.log('startPianoLogout');
  window.userDataStorage.clearData();
  pianoLogout();
}
document.addEventListener('logout-event', logout);

window.authSession.then(() => console.log('resolve authSession in login.js', 'A4'));
window.authSession.then(initUserStatusWidget);

window.addEventListener('message', receiveLoginComplete, false);
window.addEventListener('message', receiveStartLogin, false);

window.startLogin = startLogin;
window.createLoginUrl = createLoginUrl;
window.openAndReloadOnClose = openAndReloadOnClose;
window.createPendingWidget = createPendingWidget;
window.createPendingWidgetInElement = createPendingWidgetInElement;
window.loginInPage = loginInPage;
window.resendConfirmEmail = resendConfirmEmail;
window.logout = logout;
